<template>
    <div id="location-assign-grid">
        <div
            v-if="isFileSelectionEmpty"
            class="flex flex-row text-gray-600"
        >
            <div class="text-sm text-center w-full">
                No files selected for upload.
            </div>
            <div class="text-sm text-center w-full">
                Please return to the previous step.
            </div>
        </div>
        <div
            v-if="isLocationIndexEmpty"
            class="flex flex-row"
        >
            <div class="text-sm text-center w-full text-gray-600">
                No locations exist for this account.
            </div>
            <div class="text-sm text-center w-full text-primary-600">
                <router-link
                    to="/data-manager/locations"
                    class="w-full text-center hover:text-primary-400"
                >
                    Add a new location here.
                </router-link>
            </div>
            <!-- http://localhost:8889/data-manager/locations -->
        </div>
        <AgGridVue
            v-if="!isFileSelectionEmpty && !isLocationIndexEmpty"
            class="ag-theme-alpine w-full block"
            style="min-height: 250px"
            :domLayout="grid.state.domLayout.value"
            :defaultColDef="grid.state.defaultColDef.value"
            :columnDefs="grid.state.columnDefs.value"
            :rowSelection="grid.state.rowSelection.value"
            :rowHeight="grid.state.rowHeight.value"
            :rowData="grid.state.rowData.value"
            @grid-ready="grid.actions.onGridReady"
            @column-resized="grid.actions.onColumnResized"
            @selection-changed="grid.actions.onSelectionChanged"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, toRefs } from 'vue';
    import { useStore } from 'vuex';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useLocationSelectGrid } from '~CSVUploader/hooks/grid/useLocationSelectGrid';

    // <!-- TYPES -->

    /** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */
    /** @typedef {import('@/store/types/uploader/state/UploadRecord').UploadRecord} UploadRecord */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectLocationGrid',
        components: {
            AgGridVue,
        },
        props: {
            multiple: Boolean,
            prompt: {
                type: String,
                default: 'Select locations to assign.',
            },
            records: {
                /** @type {V.PropType<Map<String, UploadRecord>>} */
                type: Object,
                required: true,
            },
            locationIndex: {
                /** @type {V.PropType<Map<Number, LocationResource>>} */
                type: Object,
                required: true,
            },
        },
        emits: [
            'resize:grid',
            'alert',
            'change:rowData',
            'assign:location',
            'input:location',
        ],
        setup(props, context) {
            // ==== PROPS ====
            const { records, locationIndex } = toRefs(props);

            // ==== COMPUTED PROPERTIES ====
            /**
             * Is the file selection set empty?
             */
            const isFileSelectionEmpty = computed(() => {
                return records.value.size <= 0;
            });

            /**
             * Is the location index empty?
             */
            const isLocationIndexEmpty = computed(() => {
                return locationIndex.value.size <= 0;
            });

            // ===== GRID =====
            /**
             * Grid props.
             */
            const selectGridProps = {
                records,
                locationIndex,
            };

            /**
             * Grid API.
             */
            const selectGridActions = {
                /**
                 * @type {(event: { filename: String, id: Number }) => Promise<UploadRecord>}
                 */
                onLocationSelected: async (event) => {
                    const { filename, id } = event;
                    if (records.value.has(filename)) {
                        const record = records.value.get(filename);
                        const location = locationIndex.value.get(id);
                        context.emit('input:location', { record, location });
                        return record;
                    }
                    // Nothing affected.
                    return null;
                },
                /**
                 * @type {(event: { filename: String }) => Promise<UploadRecord>}
                 */
                onLocationDeselected: async (event) => {
                    const { filename } = event;
                    if (records.value.has(filename)) {
                        const record = records.value.get(filename);
                        context.emit('input:location', {
                            record,
                            location: null,
                        });
                        return record;
                    }
                    // Nothing affected.
                    return null;
                },
            };

            // Get the file grid module.
            const grid = useLocationSelectGrid(
                context,
                useStore(),
                selectGridProps,
                selectGridActions
            );

            // TODO: Loading overlay could be shown: https://www.ag-grid.com/vue-data-grid/overlays/

            // Configure the grid module.
            grid.useDefaultColDef({
                flex: 1,
                minWidth: 50,
                width: 100,
                resizable: true,
                sortable: true,
                filter: false,
                suppressMovable: true,
                suppressMenu: true,
                lockPosition: true,
                cellClass: 'flex justify-center items-center',
            });

            // Update the column definitions.
            grid.actions.updateColumnDefs();

            return {
                grid,
                isFileSelectionEmpty,
                isLocationIndexEmpty,
            };
        },
    });
</script>
