// <!-- API -->
import { computed } from 'vue';
import { StepContent } from '~CSVUploader/hooks/workflow/useStepContent';

// <!-- COMPOSABLES -->
import { useLocationIndex } from '@/hooks/cache/useLocationIndex';

// <!-- TYPES -->
import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
import { UploadStore } from '../store/useUploadStore';
/** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */
import { UploadRecord } from '@/store/types/uploader/state/UploadRecord';
import ActionLogger from '@/utils/ActionLogger';

/**
 * Get the content prompt.
 * @param {'loading' | 'suggesting' | 'done'} status
 */
const getContentPrompt = (status) => {
    switch (status) {
        case 'loading':
            return `Loading account locations.`;
        case 'suggesting':
            return `Suggesting locations for selected files.`;
        default:
            return `Select one location for each dataset.`;
    }
};

/**
 * Get the content status.
 * @param {Map<Number, LocationResource>} index
 * @param {any[]} locations
 * @param {any[]} suggestions
 * @param {any[]} uploading
 * @param {any[]} uploaded
 * @param {Number} limit
 * @param {Boolean} fetching
 * @param {Boolean} suggesting
 */
const getContentStatus = (
    index,
    locations,
    suggestions,
    uploading,
    uploaded,
    limit,
    fetching,
    suggesting
) => {
    const status = new Set();

    // ==== FETCHING ====
    if (fetching === true) {
        status.add(`Loading locations...`);
    }
    if (!fetching && index.size <= 0) {
        status.add(
            'No locations fetched from the server. Please create a Location.'
        );
    }
    if (!fetching && (!locations || !locations.length)) {
        status.add('No locations selected.');
    } else if (!fetching) {
        status.add(`${locations.length} out of ${limit} location(s) selected.`);
    }

    // ==== SUGGESTIONS ====
    if (suggesting === true) {
        status.add(`Loading location suggestions...`);
    }
    if (!suggesting && (!suggestions || suggestions.length <= 0)) {
        status.add('No suggested locations.');
    } else if (!suggesting) {
        status.add(`Found ${suggestions.length} suggested location(s).`);
    }

    // ==== UPLOADING / UPLOADED ====
    if (uploading.length > 0) {
        // ==== UPLOADING ====
        status.clear();
        status.add(`Uploading ${uploading.length} datasets.`);
    } else if (uploaded.length > 0) {
        // ==== UPLOADED ====
        status.clear();
        status.add(`Finished uploading datasets.`);
    }

    // ==== EXPOSE ====
    return [...status].join(' ');
};

/**
 * Handle reactivity for the location selector step.
 */
export const useLocationSelect = () => {
    /**
     * Create a dynamic computed content object.
     * @param {UploadStore} store
     * @param {Object} props
     * @param {V.Ref<Number>} props.mountKey Dependency tracking variable.
     * @param {V.Ref<Boolean>} props.isFetching Fetching locations?
     * @param {V.Ref<Boolean>} props.isSuggesting Suggesting locations?
     * @returns {V.ComputedRef<StepContent>}
     */
    const useContent = (store, { mountKey, isFetching, isSuggesting }) => {
        const $header = `Location Select`;
        return computed(() => {
            // ts-ignore
            const _ = mountKey.value;
            const index = store.api.state.cache.locations.index;
            const records = store.api.state.uploader.data.records;
            const locations = [...records.values()].filter(
                (r) => r.isLocationSelected
            );
            const suggestions = [...records.values()].filter(
                (r) => r.isLocationSuggested
            );
            const uploading = [...records.values()].filter(
                (r) => r.isUploadingDataset
            );
            const uploaded = [...records.values()].filter(
                (r) => r.isDatasetBatchUploaded
            );
            return StepContent.create()
                .setHeader($header)
                .setPrompt(
                    getContentPrompt(
                        isSuggesting.value
                            ? 'suggesting'
                            : isFetching.value
                            ? 'loading'
                            : 'done'
                    )
                )
                .setFooter(
                    getContentStatus(
                        index,
                        locations,
                        suggestions,
                        uploading,
                        uploaded,
                        records.size,
                        isFetching.value,
                        isSuggesting.value
                    )
                );
        });
    };

    /**
     * Filter actions from the form.
     * @param {V.SetupContext<any, any>} context
     * @param {UploadFormConfig<any, any>} form
     */
    const useActions = (context, form) => {
        const $actions = form.data.useDataAction(context);
        return {
            /**
             * Input the specified location (or deselect it).
             * @param {{ record: UploadRecord, location: LocationResource | null }} event
             */
            inputLocation: async (event) => {
                // Get event props.
                const { record, location } = event;
                const logger = ActionLogger.log(`[select::location]`);

                // If no record identified, nothing to do.
                if (!record || !record.filename) {
                    logger.failure(
                        `No record supplied. Nothing to select or drop.`
                    );
                    return;
                }

                // Attempt selection or drop.
                try {
                    // Get the filename.
                    const { filename = null } = record ?? {};

                    if (!location || location?.id === -1) {
                        // If location payload is null, attempt drop.
                        if (!record.location.exists) {
                            logger.warning(
                                `No location is currently selected. Nothing to drop.`
                            );
                            return;
                        } else {
                            logger.info(
                                `Attempting to drop location [${record.location.value?.id}] from record "${filename}".`
                            );
                            const affected = await $actions.drop.location(
                                filename
                            );
                            logger.success(`Location dropped!`);
                            return affected;
                        }
                    }

                    // If location payload is non-null, attempt selection.
                    const affected = await $actions.input.location(
                        filename,
                        location
                    );
                    logger.success(`Location selected!`);
                    return affected;
                } catch (err) {
                    logger.failure(err.message);
                    return null;
                }
            },
        };
    };

    // EXPOSE
    return {
        useContent,
        useActions,
        useLocationIndex,
    };
};

export default useLocationSelect;
